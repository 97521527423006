import client from "./client";

const endpoint = "/group";

const getGroup = (groupID) => client.get(`${endpoint}/me/${groupID}`);

const createGroup = ({
	userID,
	admins,
	roles,
	name,
	members,
	subscription,
	createdAt,
	visibility,
}) =>
	client.post(`${endpoint}/create`, {
		userID,
		admins,
		roles,
		name,
		members,
		subscription,
		createdAt,
		visibility,
	});

const joinGroup = ({ groupID, userID }) =>
	client.post(`${endpoint}/join/${groupID}`, { userID });

const acceptInvite = ({ groupID, userID }) =>
	client.post(`${endpoint}/acceptinvite/${groupID}`, { userID });

const findPoliticalGroups = () =>
	client.post(`${endpoint}/findallpoliticalgroups`);

const inviteByEmail = ({ groupID, emails }) =>
	client.post(`${endpoint}/invitebyemail/${groupID}`, { emails });

const acceptMember = ({ groupID, userID }) =>
	client.post(`${endpoint}/letin/${groupID}`, { userID });

const kickMember = ({ groupID, userID }) =>
	client.post(`${endpoint}/kickmember/${groupID}`, { userID });

const leaveGroup = (groupID) =>
	client.post(`${endpoint}/leavegroup/${groupID}`);

const findMyGroups = (userID) =>
	client.post(`${endpoint}/findmygroups/${userID}`);

const autoJoinOn = (groupID) =>
	client.post(`${endpoint}/autojoinon/${groupID}`);

const autoJoinOff = (groupID) =>
	client.post(`${endpoint}/autojoinoff/${groupID}`);

const checkAutoJoin = (groupID) =>
	client.post(`${endpoint}/checkautojoin/${groupID}`);

const getGroupInfo = (groupID) =>
	client.post(`${endpoint}/groupinfo/${groupID}`);

const checkDNDPN = ({ groupID, userID }) =>
	client.post(`${endpoint}/checkdndpn/${groupID}`, { userID });

const postNotificationsOn = ({ groupID, userID }) =>
	client.post(`${endpoint}/postnotificationspush/${groupID}`, { userID });

const postNotificationsOff = ({ groupID, userID }) =>
	client.post(`${endpoint}/postnotificationspull/${groupID}`, { userID });

const doNotDisturbOn = ({ groupID, userID }) =>
	client.post(`${endpoint}/donotdisturbspush/${groupID}`, { userID });

const doNotDisturbOff = ({ groupID, userID }) =>
	client.post(`${endpoint}/donotdisturbspull/${groupID}`, { userID });

const changeName = ({ groupID, name }) =>
	client.post(`${endpoint}/changename/${groupID}`, { name });

const addAdmin = ({ groupID, userID }) =>
	client.post(`${endpoint}/addadmin/${groupID}`, { userID });

const removeAdmin = ({ groupID, userID }) =>
	client.post(`${endpoint}/removeadmin/${groupID}`, { userID });

const submitDomain = ({ groupID, domain }) =>
	client.post(`${endpoint}/setrequireddomain/${groupID}`, { domain });

const removeDomain = ({ groupID }) =>
	client.post(`${endpoint}/unsetrequireddomain/${groupID}`);

const checkDomain = ({ groupID }) =>
	client.post(`${endpoint}/checkrequireddomain/${groupID}`);

const findUserGroups = () => client.post(`${endpoint}/findusergroups`);

const addTitlesToMembers = ({ titles, userIDs, identifier }) =>
	client.post(`${endpoint}/addtitlestomembers`, {
		titles,
		userIDs,
		identifier,
	});

const uploadCategories = ({ groupID, categories }) =>
	client.post(`${endpoint}/addgroupcategories/${groupID}`, {
		categories,
	});

const addCategoriesToSelf = ({ categories, identifier }) =>
	client.post(`${endpoint}/addcategoriestoself`, {
		categories,
		identifier,
	});

const removeCategoryFromSelf = ({ category, identifier }) =>
	client.post(`${endpoint}/removecategoryfromself`, {
		category,
		identifier,
	});

const removeCategory = ({ groupID, category }) =>
	client.post(`${endpoint}/removegroupcategory/${groupID}`, {
		category,
	});

const removeTitleFromMember = ({ title, userID, identifier }) =>
	client.post(`${endpoint}/removetitlefrommember`, {
		title,
		userID,
		identifier,
	});
const getMyCategories = ({ identifier }) =>
	client.post(`${endpoint}/mycategories`, {
		identifier,
	});

const checkAdmin = (groupID) =>
	client.post(`${endpoint}/checkadmin/${groupID}`);

const newTopic = ({ groupID, topic }) =>
	client.post(`${endpoint}/newTopic/${groupID}`, { topic });

const currentTopic = (identifier) =>
	client.post(`${endpoint}/currentTopic`, { identifier });

const allTopics = (identifier) =>
	client.post(`${endpoint}/allTopics`, { identifier });
const getAvailableGroups = () => client.post(`${endpoint}/availableGroups`);

const groupsApi = {
	getGroup,
	joinGroup,
	inviteByEmail,
	acceptMember,
	kickMember,
	findMyGroups,
	autoJoinOn,
	autoJoinOff,
	checkAutoJoin,
	getGroupInfo,
	checkDNDPN,
	postNotificationsOff,
	postNotificationsOn,
	doNotDisturbOn,
	doNotDisturbOff,
	changeName,
	findPoliticalGroups,
	acceptInvite,
	addAdmin,
	removeAdmin,
	createGroup,
	findUserGroups,
	submitDomain,
	removeDomain,
	checkDomain,
	leaveGroup,
	addTitlesToMembers,
	checkAdmin,
	removeTitleFromMember,
	uploadCategories,
	removeCategory,
	addCategoriesToSelf,
	getMyCategories,
	removeCategoryFromSelf,
	newTopic,
	allTopics,
	currentTopic,
	getAvailableGroups,
};

export default groupsApi;
