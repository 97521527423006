import React, { useState, useEffect } from "react";

export default function CardOption({
	item,
	voteHandler,
	optionText,
	votes,
	length,
	pressed,
	voted,
	index,
	decision,
}) {
	const [selected, setSelected] = useState(false);
	const [votedOn, setVotedOn] = useState(false);

	useEffect(() => {
		if (decision) {
			if (decision.option === optionText && pressed) {
				setSelected(true);
			} else {
				setSelected(false);
			}
		}
	}, [decision, pressed]);
	useEffect(() => {
		// console.log(decision);
		if (decision) {
			if (decision.option === optionText && voted) {
				setVotedOn(true);
			} else {
				setVotedOn(false);
			}
		}
	}, [decision, voted]);
	const votePress = async () => {
		await voteHandler(item);
	};
	// useEffect(() => {
	// 	console.log(decision === optionText);
	// });

	return (
		<button
			className={votedOn ? "voted option" : "option"}
			disabled={voted}
			onPress={() => {
				votePress();
			}}
		>
			<p>{optionText}</p>
			{/* <p className="result">{votes}</p> */}
			{/* {voted && <div className="percentage-bar"></div>} */}
		</button>
	);
}
