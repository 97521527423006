import React from "react";

export default function MessageModal({
	message,
	subMessage,
	visible,
	setVisible,
}) {
	return (
		<div className={visible ? "modal-container" : "hidden"}>
			<div className={visible ? "modal" : "hidden"}>
				<h5>{message}</h5>
				<button onClick={() => setVisible(false)}>
					<p className="slicedID">Close X</p>
				</button>
			</div>
		</div>
	);
}
