import React, { useState, useEffect } from "react";
import "./styles/styles.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "./providers/Provider";
import InitialRouter from "./navigation/initialRouter";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
function App() {
	// const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	// const theme = useMemo(
	// 	() =>
	// 		createTheme({
	// 			palette: {
	// 				type: prefersDarkMode ? "dark" : "light",
	// 			},
	// 		}),
	// 	[prefersDarkMode]
	// );
	// TODO add the dark theme for css to this when you're done^^
	const [darkTheme, setDarkTheme] = useState(false);
	// useEffect(() => {
	// 	setDarkTheme(Math.random() < 0.5);
	// }, []);
	const theme = createTheme({
		palette: {
			primary: {
				main: "#6840d9",
				light: "#8f67ff",
				dark: "#59009f",
			},
			secondary: {
				main: darkTheme ? "#151515" : "#fcfcfc",
				light: darkTheme ? "#212121" : "#eeeeee",
				dark: darkTheme ? "#000" : "#fff",
			},
		},
	});
	return (
		<ThemeProvider theme={theme}>
			<div
				className={"theme " + (darkTheme ? "theme--dark" : "theme--default")}
			>
				<Router>
					<Provider>
						<InitialRouter />
					</Provider>
				</Router>
			</div>
		</ThemeProvider>
	);
}
export default App;
