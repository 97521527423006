import React, { useMemo, useState, useEffect, useRef } from "react";

import authApi from "../api/auth";

import { useHistory } from "react-router";
import jwt_decode from "jwt-decode";
import groupsApi from "../api/groups.js";
import authUserApi from "../api/auth";
import usersApi from "../api/users";

const AllData = () => {
	const history = useHistory();

	const [isAdmin, setIsAdmin] = useState(false);
	const [authorizationToken, setAuthorizationToken] = useState("");

	const [cardName, setCardName] = useState("");
	const [userID, setUserID] = useState("");
	const [email, setEmail] = useState("");
	const [loggedIn, setLoggedIn] = useState(false);
	const [verified, setVerified] = useState("false");
	const [profileSetUp, setProfileSetUp] = useState(false);
	const [expoNotificationToken, setExpoNotificationToken] = useState("");
	const [name, setName] = useState("");
	const [emailVerified, setEmailVerified] = useState("");
	const [phoneVerified, setPhoneVerified] = useState("");
	const [birthdayDone, setBirthdayDone] = useState("");
	const [locationDone, setLocationDone] = useState("");

	const [chosenGroup, setChosenGroup] = useState("");

	const [userType, setUserType] = useState("");
	const [globalIdentifier, setGlobalIdentifier] = useState("");
	const [onGov, setOnGov] = useState(false);
	const [ad, setAd] = useState(false);
	const [sent, setSent] = useState(false);

	const [focusPostID, setFocusPostID] = useState("");
	const [focusTopic, setFocusTopic] = useState("");

	const [otherUserID, setOtherUserID] = useState("");
	const [globalAdmin, setGlobalAdmin] = useState(false);

	const [topic, setTopic] = useState("none");
	const [proposal, setProposal] = useState("");
	const [body, setBody] = useState("");
	const [options, setOptions] = useState({});
	const [optionsType, setOptionsType] = useState("Default");

	useEffect(() => {
		const adminCheck = async () => {
			const result = await groupsApi.getGroup(globalIdentifier);
			if (!result.ok) {
				console.log({ getgrouperr: result });
			} else {
				setCardName(result.data.name);
				setChosenGroup(result.data);
				if (result.data.admins) {
					let thing = result.data.admins.filter(
						(object) => object.userID === userID
					);
					if (thing.length === 0) {
						setIsAdmin(false);
					} else {
						setIsAdmin(true);
					}
				}
			}
		};
		adminCheck(globalIdentifier);
	}, [globalIdentifier]);

	// useEffect(() => {
	// 	const token = localStorage.getItem("token");
	// 	const tempIdentifier = localStorage.getItem("globalIdentifier");

	// 	if (token) {
	// 		setUserID(jwt_decode(token)._id);
	// 		setLoggedIn(true);
	// 		history.push("/home");
	// 	}
	// 	if (tempIdentifier) {
	// 		selectGroup(tempIdentifier);
	// 	}
	// }, []);

	// const selectGroup = async (groupID) => {
	// 	const result = await groupsApi.getGroup(groupID);
	// 	if (!result.ok) {
	// 		console.log({ getGroupErr: result });
	// 	} else {
	// 		setGlobalIdentifier(groupID);
	// 		setCardName(result.data.name);
	// 		setChosenGroup(result.data);
	// 		if (result.data.political) {
	// 			setOnGov(true);
	// 		} else {
	// 			setOnGov(false);
	// 		}
	// 		if (result.data.admins) {
	// 			let done = await result.data.admins.filter(
	// 				(object) => object.userID === userID
	// 			);
	// 			if (done.length === 0) {
	// 				setIsAdmin(false);
	// 				setGlobalAdmin(false);
	// 			} else {
	// 				setIsAdmin(true);
	// 				setGlobalAdmin(true);
	// 			}
	// 		}
	// 	}
	// };
	// const signOut = async () => {
	// 	await localStorage.removeItem("token");
	// 	await localStorage.removeItem("identifier");
	// 	setLoggedIn(false);
	// 	setUserID("");
	// 	setProfileSetUp(false);
	// 	setAuthorizationToken("");
	// 	setCardName("");
	// 	history.push("/login");
	// };

	const checkProfileSetup = async () => {
		const result = await authUserApi.getProfileSetUp(authorizationToken);
		const nameResult = await usersApi.getUser(userID);
		if (!result.ok) {
			console.log({ checkProfileSetupErr: result });
		} else {
			setEmailVerified(result.data.emailVerified);
			setPhoneVerified(result.data.phoneVerified);
			setVerified(result.data.verified);
			setName(nameResult.data.name);
			if (result.data.locationLength > 0) {
				setLocationDone(true);
			} else {
				setLocationDone(false);
			}
			if (result.data.birthday) {
				setBirthdayDone(true);
			} else {
				setBirthdayDone(false);
			}
			if (
				result.data.emailVerified &&
				result.data.phoneVerified &&
				result.data.locationLength > 0 &&
				nameResult.data.name !== ("none" || "") &&
				result.data.birthday
			) {
				setProfileSetUp(true);
			} else {
				setProfileSetUp(false);
			}
		}
	};

	useEffect(() => {
		checkProfileSetup();
		console.log("hey");
	}, [userID, authorizationToken]);

	const provider = useMemo(
		() => ({
			globalAdmin,
			setGlobalAdmin,
			name,
			setName,
			emailVerified,
			setEmailVerified,
			setLocationDone,
			locationDone,
			setPhoneVerified,
			phoneVerified,
			birthdayDone,
			setBirthdayDone,
			email,
			setEmail,
			authorizationToken,
			setAuthorizationToken,

			expoNotificationToken,
			setExpoNotificationToken,
			userType,
			setUserType,
			globalIdentifier,
			setGlobalIdentifier,

			profileSetUp,
			setProfileSetUp,

			loggedIn,
			setLoggedIn,

			verified,
			setVerified,

			userID,
			setUserID,

			// location variables

			//gov

			otherUserID,
			setOtherUserID,
			// Post Variables
			topic,
			setTopic,

			body,
			setBody,

			proposal,
			setProposal,
			options,
			setOptions,
			optionsType,
			setOptionsType,

			focusPostID,
			setFocusPostID,
			focusTopic,
			setFocusTopic,

			ad,
			setAd,
			sent,
			setSent,
			//private poll

			onGov,
			setOnGov,
			cardName,
			setCardName,
			chosenGroup,
			setChosenGroup,

			isAdmin,
			setIsAdmin,
		}),
		[
			globalAdmin,
			setGlobalAdmin,
			name,
			setName,
			emailVerified,
			setEmailVerified,
			setLocationDone,
			locationDone,
			setPhoneVerified,
			phoneVerified,
			birthdayDone,
			setBirthdayDone,
			email,
			setEmail,
			authorizationToken,
			setAuthorizationToken,
			expoNotificationToken,
			setExpoNotificationToken,
			userType,
			setUserType,
			globalIdentifier,
			setGlobalIdentifier,
			profileSetUp,
			setProfileSetUp,
			loggedIn,
			setLoggedIn,
			verified,
			setVerified,
			userID,
			setUserID,
			otherUserID,
			setOtherUserID,
			// Post Variables
			topic,
			setTopic,
			body,
			setBody,
			proposal,
			setProposal,
			options,
			setOptions,
			optionsType,
			setOptionsType,
			focusPostID,
			setFocusPostID,
			focusTopic,
			setFocusTopic,
			ad,
			setAd,
			sent,
			setSent,

			onGov,
			setOnGov,
			cardName,
			setCardName,
			chosenGroup,
			setChosenGroup,
			isAdmin,
			setIsAdmin,
		]
	);
	return provider;
};

export default AllData;
