import React from "react";

import AppStore from "../../assets/images/app_store_download.png";
import PlayStore from "../../assets/images/google-play-badge-logo-png-transparent.png";
export default function Landing() {
	return (
		<div className='focus-post-wrapper'>
			<div className='sub-header'>
				<h3>Download Quae to Vote Every Day</h3>
				<a
					href='https://apps.apple.com/us/app/quae/id1555653993'
					target='_blank'
				>
					<img src={AppStore} className='app-store' />
				</a>
				<a
					href='https://play.google.com/store/apps/details?id=com.quae.quaeapp&hl=en_US&gl=US'
					target='_blank'
				>
					<img src={PlayStore} className='app-store' />
				</a>
			</div>
			<a href='https://quae.app' target='_blank' className='sub-header'>
				<p>Learn more</p>
			</a>
		</div>
	);
}

{
	/* <div>
								{!prioritized && globalAdmin && !tempPrioritized && (
									<button
										onClick={() => {
											setPrioritizing(!prioritizing);
										}}
									>
										prioritize post
									</button>
								)}
								{!resolved && globalAdmin && !tempResolved && (
									<button
										onClick={() => {
											setResolving(!resolving);
										}}
									>
										resolve post
									</button>
								)}
							</div>

							{prioritizing && (
								<div>
									{/* <PriorityRange {...priorityProps} /> 
									<div>
										<button
											onClick={() => {
												setPrioritizing(false);
												setTempPrioritized(true);
												prioritizePost();
											}}
										>
											confirm
										</button>
										<button
											onClick={() => {
												setPrioritizing(false);
											}}
										>
											cancel
										</button>
									</div>
								</div>
							)}

							{tempPrioritized && (
								<div>
									<div>Successfully Prioritized</div>
								</div>
							)}

							{resolving && (
								<div>
									<div>
										<button
											onClick={() => {
												setResolving(false);
												setTempResolved(true);
												resolvePost();
											}}
										>
											confirm
										</button>
										<button
											onClick={() => {
												setResolving(false);
											}}
										>
											cancel
										</button>
									</div>
								</div>
							)}

							{tempResolved && <div>Successfully Resolved</div>} */
}
