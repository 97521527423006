import React, { useContext } from "react";
import { Context } from "../../../providers/Provider.jsx";
import { useHistory } from "react-router-dom";
import { Avatar } from "@material-ui/core";

export default function CardHeader({
	cardHeaderUserID,
	cardProfilePic,
	cardName,
	slicedID,
	cardDate,
	topic,
	titles,
	setModalFocusPostVisible,
}) {
	const history = useHistory();

	const { userID, setOtherUserID, setFocusTopic } = useContext(Context);
	return (
		<div className="card-header">
			{/* start of header */}

			{/* start of profile pic */}
			<div>
				<Avatar
					alt={cardName}
					src={cardProfilePic}
					to={"/otherProfile"}
					className="picture"
				/>
			</div>

			<div className="text">
				<div className="top">
					<p>{cardName}</p>
					<p className="slicedID">#{slicedID}</p>

					<div className="dot"></div>
					<p>{cardDate}</p>
				</div>

				<div className="bottom">
					<div className="topic">
						<p className="white">{topic}</p>
					</div>
					{titles && (
						<div>
							{titles.map((title) => {
								return (
									<div className="title">
										<p className="bold purple">{title}</p>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
