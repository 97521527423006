import client from "./client";

const endpoint = "/posts";

const getPost = (postID) => client.get(`${endpoint}/me/${postID}`);

const getUserPosts = ({ userID, identifier }) =>
	client.post(`${endpoint}/userPosts/${userID}`, { identifier });

const sortByNew = ({ identifier, time, userID }) =>
	client.post(`${endpoint}/sortbynew/`, { identifier, time, userID });

const sortByResolved = ({ identifier, time, userID }) =>
	client.post(`${endpoint}/sortbyresolved/`, { identifier, time, userID });

const sortByHot = ({ identifier, time, userID }) =>
	client.post(`${endpoint}/sortByHot/`, { identifier, time, userID });

const topOfMonth = ({ identifier, time, userID }) =>
	client.post(`${endpoint}/topofmonth/`, { identifier, time, userID });

const getPriorityPosts = ({ identifier, time }) =>
	client.post(`${endpoint}/findpriorityposts/`, { identifier, time });

const getAllPriorityPosts = ({ identifier, time }) =>
	client.post(`${endpoint}/allpriorityposts/`, { identifier, time });

const getTopicPosts = ({ topic, identifier }) =>
	client.post(`${endpoint}/topicposts/${topic}`, { identifier });

const queryForUser = ({ postID, userID }) =>
	client.get(`${endpoint}/queryforuser/${postID}/${userID}`);

const findUserVotes = (identifier) =>
	client.post(`${endpoint}/finduservotes`, { identifier });

const prioritizePost = ({ postID, time, range }) =>
	client.post(`${endpoint}/upgradetopriority/${postID}`, {
		time,
		range,
	});

const resolvePost = ({ postID }) =>
	client.post(`${endpoint}/resolvepost/${postID}`);

const report = ({ postID, userID, type, message }) =>
	client.post(`${endpoint}/report/${postID}`, {
		userID,
		type,
		message,
	});

const findMyReports = ({ postID, userID }) =>
	client.get(`${endpoint}/findmyreports/${postID}/${userID}`);

const getVoteNumbers = (postID) =>
	client.get(`${endpoint}/getvotenumbers/${postID}`);

const vote = ({ postID, decision, time }) =>
	client.post(`${endpoint}/voteOverTime/${postID}`, {
		decision,
		time,
	});
const addNewOption = ({ postID, decision, time }) =>
	client.post(`${endpoint}/addOption/${postID}`, {
		decision,
		time,
	});
const post = ({
	topic,
	proposal,
	body,
	options,
	optionsType,
	userID,
	sent,
	ad,
	priority,
	createdAt,
	identifier,
}) =>
	client.post(`${endpoint}/post`, {
		topic,
		proposal,
		body,
		options,
		optionsType,
		userID,
		sent,
		ad,
		priority,
		createdAt,
		identifier,
	});

const saveAsDraft = ({ postObject, createdAt, identifier }) =>
	client.post(`${endpoint}/saveasdraft`, {
		postObject,
		createdAt,
		identifier,
	});
const getMyDrafts = (identifier) =>
	client.post(`${endpoint}/getmydrafts`, {
		identifier,
	});
const deleteDraft = (postID) =>
	client.post(`${endpoint}/deletedraft`, {
		postID,
	});
const getAnalytics = ({ postID, ageRange, categories }) =>
	client.post(`${endpoint}/analyticsfilter/${postID}`, {
		ageRange,
		categories,
	});

const checkActive = (postID) =>
	client.post(`${endpoint}/checkActive`, {
		postID,
	});

const sendVoteCode = ({ postID, email }) =>
	client.post(`${endpoint}/sendVoteCode`, {
		postID,
		email,
	});
const quickVote = ({ postID, decision, time, code, email }) =>
	client.post(`${endpoint}/quickVote`, {
		postID,
		decision,
		time,
		code,
		email,
	});

const postsApi = {
	getPost,
	getUserPosts,
	sortByNew,
	sortByHot,
	sortByResolved,
	topOfMonth,
	post,
	getPriorityPosts,
	getAllPriorityPosts,
	getTopicPosts,
	prioritizePost,
	resolvePost,
	vote,
	addNewOption,
	queryForUser,
	findUserVotes,
	report,
	findMyReports,
	getVoteNumbers,
	saveAsDraft,
	getMyDrafts,
	deleteDraft,
	getAnalytics,
	checkActive,
	sendVoteCode,
	quickVote,
};
export default postsApi;
