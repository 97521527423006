import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../providers/Provider";

import moment from "moment";
import { useParams } from "react-router";
import postsApi from "../../api/posts";
import usersApi from "../../api/users";
import chatsApi from "../../api/chats";
import groupsApi from "../../api/groups";
import { Avatar, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import Reply from "../../components/shared/cardComponents/reply";
import CardOption from "../../components/shared/cardComponents/cardOption";
import CardBody from "../../components/shared/cardComponents/cardBody";
import CardHeader from "../../components/shared/cardComponents/cardHeader";
import CardFooter from "../../components/shared/cardComponents/cardFooter";
import SendIcon from "@material-ui/icons/Send";
import AppStore from "../../assets/images/app_store_download.png";
import PlayStore from "../../assets/images/google-play-badge-logo-png-transparent.png";
import AddIcon from "@material-ui/icons/Add";
export default function FocusPostPreview({ pathID }) {
	// const { pathID } = useParams();
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [enterEmailModalVisible, setEnterEmailModalVisible] = useState(false);
	const [enterCodeModalVisible, setEnterCodeModalVisible] = useState(false);
	const [unavailableModalVisible, setUnavailableModalVisible] = useState(false);
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const [alreadyVotedModalVisible, setAlreadyVotedModalVisible] =
		useState(false);
	const [modalVisible, setModalVisible] = useState(true);

	const [errMessage, setErrMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [voted, setVoted] = useState(false);
	const [pressed, setPressed] = useState(false);
	const [cardTotalOptionData, setCardTotalOptionData] = useState([]);
	const [cardDecision, setCardDecision] = useState("");
	const [errDecision, setErrDecision] = useState("");
	const [requiredDomain, setRequiredDomain] = useState("");
	const history = useHistory();
	const {
		loggedIn,
		verified,
		setTopic,
		userID,
		setOtherUserID,
		profileSetUp,
		chosenGroup,
		globalIdentifier,
		onGov,
		focusPostID,
	} = useContext(Context);
	const PostID = focusPostID;

	const [cardTopic, setCardTopic] = useState("");
	const [cardName, setCardName] = useState("");
	const [cardProfilePic, setCardProfilePic] = useState("");
	const [cardDate, setCardDate] = useState("");
	const [cardUserID, setCardUserID] = useState("123456789123456789012345");
	const [cardTitles, setCardTitles] = useState([]);
	const [globalAdmin, setGlobalAdmin] = useState(false);
	let slicedID = cardUserID.slice(20, 24);
	// const [pressed, setPressed] = useState(false);
	// const [voted, setVoted] = useState(false);
	const [relDate, setRelDate] = useState("");
	const [newOption, setNewOption] = useState("");

	const [optionsArray, setOptionsArray] = useState([]);
	const [optionsValues, setOptionsValues] = useState([]);
	const [prioritized, setPrioritized] = useState(true);
	const [resolved, setResolved] = useState(true);

	const [range, setRange] = useState("hour");

	const [tempDecision, setTempDecision] = useState(null);
	const [undoAnimationVisible, setUndoAnimationVisible] = useState(false);
	const [toggleData, setToggleData] = useState("Total Votes");
	const [verifiedOptionData, setVerifiedOptionData] = useState([]);
	const [unverifiedOptionData, setUnverifiedOptionData] = useState([]);
	const [totalOptionData, setTotalOptionData] = useState([]);
	const [refreshing, setRefreshing] = useState(false);
	const [chats, setChats] = useState([]);
	const [message, setMessage] = useState("");
	const [votedPost, setVotedPost] = useState({
		proposal: "",
		body: "",
		createdAt: "",
	});

	const [postBody, setPostBody] = useState("none");
	var [isPress, setIsPress] = useState(4);
	var [pressVerified, setPressVerified] = useState("verified");
	const [decision, setDecision] = useState("none");
	const [modalLoggedInVisible, setModalLoggedInVisible] = useState(false);
	const [modalCompleteProfileVisible, setModalCompleteProfileVisible] =
		useState(false);
	const [modalReportVisible, setModalReportVisible] = useState(false);
	const [modalPostAnalyticsVisible, setModalPostAnalyticsVisible] =
		useState(false);
	const [togglePosition, setTogglePosition] = useState("Total Votes");
	const [totalVotes, setTotalVotes] = useState(null);
	const [postOptionsType, setPostOptionsType] = useState("");
	const [postLoading, setPostLoading] = useState(true);
	const [newOptionLoading, setNewOptionLoading] = useState(false);
	const [chatLength, setChatLength] = useState(0);
	const verifiedDataFunction = (dataKeys, dataValues) => {
		let tempArray = [];
		var i;
		let totalVerifiedVotes = dataValues.reduce((a, b) => a + b, 0);
		for (i = 0; i < dataKeys.length; i++) {
			tempArray.push({
				option: dataKeys[i],
				votes: dataValues[i],
				length: ((100 * dataValues[i]) / totalVerifiedVotes).toString() + "%",
			});
		}
		return tempArray;
	};

	const totalDataFunction = (
		dataKeys,
		dataValuesUnverified,
		dataValuesVerified
	) => {
		let tempArray = [];
		var i;
		let totalVotes =
			dataValuesUnverified.reduce((a, b) => a + b, 0) +
			dataValuesVerified.reduce((a, b) => a + b, 0);
		for (i = 0; i < dataKeys.length; i++) {
			tempArray.push({
				option: dataKeys[i],
				votes: dataValuesUnverified[i] + dataValuesVerified[i],
				length:
					(
						(100 * (dataValuesUnverified[i] + dataValuesVerified[i])) /
						totalVotes
					).toString() + "%",
			});
		}
		return tempArray;
	};

	const getVoteNumbers = async () => {
		const result = await postsApi.getVoteNumbers(pathID);
		if (!result.ok) {
			console.log({ getVoteNumbersErr: result });
		} else {
			setTotalOptionData(
				totalDataFunction(
					Object.keys(result.data.unverified),
					Object.values(result.data.unverified),
					Object.values(result.data.verified)
				)
			);
			setCardTotalOptionData(
				totalDataFunction(
					Object.keys(result.data.unverified),
					Object.values(result.data.unverified),
					Object.values(result.data.verified)
				)
			);
			setVerifiedOptionData(
				verifiedDataFunction(
					Object.keys(result.data.verified),
					Object.values(result.data.verified)
				)
			);
			setUnverifiedOptionData(
				verifiedDataFunction(
					Object.keys(result.data.unverified),
					Object.values(result.data.unverified)
				)
			);
			setTotalVotes(
				Object.values(result.data.unverified).reduce((a, b) => a + b, 0) +
					Object.values(result.data.verified).reduce((a, b) => a + b, 0)
			);
		}
	};

	const getPost = async () => {
		setPostLoading(true);
		const result = await postsApi.getPost(pathID);
		if (!result.ok) {
			console.log({ getPostErr: result });
		} else {
			setVotedPost(result.data);

			if (result.data.priority) {
				if (result.data.priority.prioritized) {
					setPrioritized(true);
				} else {
					setPrioritized(false);
				}
			}
			if (result.data.resolved) {
				setResolved(true);
			} else {
				setResolved(false);
			}
			setCardUserID(result.data.userID);
			setPostBody(result.data.body);
			setCardTopic(result.data.topic);
			setPostOptionsType(result.data.optionsType);
			if (result.data.options) {
				setOptionsArray(Object.keys(result.data.options.verified));
				setOptionsValues(Object.values(result.data.options.verified));
				setVerifiedOptionData(
					verifiedDataFunction(
						Object.keys(result.data.options.verified),
						Object.values(result.data.options.verified)
					)
				);
				setUnverifiedOptionData(
					verifiedDataFunction(
						Object.keys(result.data.options.unverified),
						Object.values(result.data.options.unverified)
					)
				);
				setTotalVotes(
					Object.values(result.data.options.unverified).reduce(
						(a, b) => a + b,
						0
					) +
						Object.values(result.data.options.verified).reduce(
							(a, b) => a + b,
							0
						)
				);
				setTotalOptionData(
					totalDataFunction(
						Object.keys(result.data.options.unverified),
						Object.values(result.data.options.unverified),
						Object.values(result.data.options.verified)
					)
				);
			}

			// checkAdmin(result.data.identifier);
			getUser();
		}
	};

	const getUser = async () => {
		const result = await usersApi.getUser(cardUserID);
		if (!result.ok) {
			console.log({ getProfileSetUpErr: result });
		} else {
			setCardName(result.data.name);
			setCardProfilePic(result.data.profilePic);
			if (result.data.userTitles) {
				setCardTitles(result.data.userTitles[globalIdentifier]);
			}
			setPostLoading(false);
		}
	};

	const voteHandler = async (item) => {
		setPressed(true);
		setDecision(item);
		setCardDecision(item);
		setTempDecision(item);
		setEnterEmailModalVisible(true);
		//set email modal visible
	};
	const vote = async (decision, postID, topic, code, email) => {
		const result = await postsApi.quickVote({
			postID: pathID,
			decision: decision.option,
			time: new Date(moment()),
			code,
			email,
		});
		if (!result.ok) {
			console.log({ voteErr: result });
		} else {
			setVoted(true);
			getVoteNumbers();
			console.log("vote function: voted");
		}
	};

	const checkActive = async () => {
		const result = await postsApi.checkActive(pathID);
		if (!result.ok) {
			console.log("checkActiveErr");
		} else {
			console.log(result);
			if (!result.data) {
				setUnavailableModalVisible(true);
			} else {
				getPost(pathID);
				setUnavailableModalVisible(false);

				setRequiredDomain(result.data);
			}
		}
	};
	const sendVoteCode = async () => {
		console.log(email, pathID);
		const result = await postsApi.sendVoteCode({ postID: pathID, email });
		if (!result.ok) {
			console.log("checkActiveErr");
		} else {
			//set code visible
			setEnterEmailModalVisible(false);
			setEnterCodeModalVisible(true);
		}
	};
	const quickVote = async () => {
		const result = await postsApi.quickVote({
			postID: pathID,
			decision: decision.option,
			time: new Date(moment()),
			code,
			email,
		});
		if (!result.ok) {
			console.log("checkActiveErr");
		} else {
			// setVoted(true);
			// getVoteNumbers();
			if (result.data !== "alreadyvoted") {
				setEnterCodeModalVisible(false);
				setConfirmModalVisible(true);
			} else {
				setErrMessage("You've already voted on this post.");
				setAlreadyVotedModalVisible(true);
				setEnterCodeModalVisible(false);
			}
			console.log("vote function: voted");
		}
	};

	useEffect(() => {
		getPost(pathID);

		setRelDate(
			moment(votedPost.createdAt, "YYYYMMDD hh:mm:ss")
				.utcOffset(+0, true)

				.fromNow()
		);
		setCardDate(relDate);
	}, [cardTopic, cardDate]);
	const [blurred, setBlurred] = useState(false);

	return (
		<div>
			<div className={unavailableModalVisible ? "modal-container" : "hidden"}>
				<div className='sub-header'>
					<h3>Download Quae to Vote Every Day</h3>
					<a
						href='https://apps.apple.com/us/app/quae/id1555653993'
						target='_blank'
					>
						<img src={AppStore} className='app-store' />
					</a>
					<a
						href='https://play.google.com/store/apps/details?id=com.quae.quaeapp&hl=en_US&gl=US'
						target='_blank'
					>
						<img src={PlayStore} className='app-store' />
					</a>
				</div>

				<div className={unavailableModalVisible ? "modal" : "hidden"}>
					<h4>Oops, This Post Link Expired</h4>
					<h6>Contact the Poll Author to Restart the Link</h6>
					<button
						onClick={() => {
							{
								setUnavailableModalVisible(false);
							}
						}}
					>
						<p className='slicedID'>Close X</p>
					</button>
				</div>
			</div>
			<div className={alreadyVotedModalVisible ? "modal-container" : "hidden"}>
				<div className={alreadyVotedModalVisible ? "modal" : "hidden"}>
					<h5>Oops, You've already Voted on this post</h5>

					<button
						onClick={() => {
							{
								setAlreadyVotedModalVisible(false);
							}
						}}
					>
						<p className='slicedID'>Close X</p>
					</button>
				</div>
			</div>
			<div className={enterEmailModalVisible ? "modal-container" : "hidden"}>
				<div className={enterEmailModalVisible ? "modal" : "hidden"}>
					<h5>Decision: {decision.option}</h5>

					<h5>Enter Your Stevens Email To Validate Your Decision</h5>
					{errMessage === "This is not available for your email domain" && (
						<h5>{errMessage}</h5>
					)}
					<div className='text-field-wrapper'>
						<TextField
							label={"email"}
							color={"primary"}
							variant={"filled"}
							InputProps={{ className: "input" }}
							InputLabelProps={{ className: "label" }}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							value={email}
							style={{ width: "100%" }}
							InputProps={{
								endAdornment: (
									<Button
										onClick={() => {
											if (email.length > 4) {
												if (requiredDomain !== "none") {
													if (email.includes(requiredDomain)) {
														sendVoteCode();
													} else {
														setErrMessage(
															"This is not available for your email domain"
														);
													}
												} else {
													sendVoteCode();
												}
											}
										}}
										color='primary'
										variant='contained'
										size='medium'
										style={{ borderRadius: 5 }}
									>
										Submit
									</Button>
								),
							}}
						/>
					</div>
					<button
						onClick={() => {
							{
								setEnterEmailModalVisible(false);
								setEmail("");
							}
						}}
					>
						<p className='slicedID'>Close X</p>
					</button>
				</div>
			</div>
			<div className={enterCodeModalVisible ? "modal-container" : "hidden"}>
				<div className={enterCodeModalVisible ? "modal" : "hidden"}>
					<h5>Decision: {decision.option}</h5>
					<h5>We've emailed you a verification code</h5>
					<h6>Enter your code below </h6>
					<div className='text-field-wrapper'>
						<TextField
							label={"verification code"}
							color={"primary"}
							variant={"filled"}
							InputProps={{ className: "input" }}
							InputLabelProps={{ className: "label" }}
							onChange={(e) => {
								setCode(e.target.value);
							}}
							value={code}
							style={{ width: "100%" }}
							InputProps={{
								endAdornment: (
									<div>
										{code.length === 7 && (
											<Button
												onClick={() => {
													quickVote();
												}}
												color='primary'
												variant='contained'
												size='medium'
												style={{ borderRadius: 5 }}
											>
												Submit
											</Button>
										)}
									</div>
								),
							}}
						/>
					</div>
				</div>
			</div>
			<div className={confirmModalVisible ? "modal-container" : "hidden"}>
				<div className={confirmModalVisible ? "modal" : "hidden"}>
					<h4>Thank You! Your Vote Has Been Recorded</h4>
					<h6>You Can close this tab now</h6>
					<h4 style={{ paddingTop: 30 }}>Join The Stevens Quae Community</h4>
					<a
						href='https://apps.apple.com/us/app/quae/id1555653993'
						target='_blank'
					>
						<img src={AppStore} className='app-store' />
					</a>
					<a
						href='https://play.google.com/store/apps/details?id=com.quae.quaeapp&hl=en_US&gl=US'
						target='_blank'
					>
						<img src={PlayStore} className='app-store' />
					</a>
					<button onClick={() => setConfirmModalVisible(false)}>
						<p className='slicedID'>Close X</p>
					</button>
				</div>
			</div>
			<div>
				{!postLoading && (
					<div className={blurred ? "blurred" : ""}>
						<div className='sub-header'>
							<h3>Download Quae to Vote Every Day</h3>
							<a
								href='https://apps.apple.com/us/app/quae/id1555653993'
								target='_blank'
							>
								<img src={AppStore} className='app-store' />
							</a>
							<a
								href='https://play.google.com/store/apps/details?id=com.quae.quaeapp&hl=en_US&gl=US'
								target='_blank'
							>
								<img src={PlayStore} className='app-store' />
							</a>
						</div>

						<div className='card'>
							<div className='card-content'>
								<CardHeader
									cardHeaderUserID={votedPost.UserID}
									cardProfilePic={cardProfilePic}
									cardName={cardName}
									slicedID={cardUserID.slice(20, 24)}
									cardDate={moment(votedPost.createdAt, "YYYYMMDD hh:mm:ss")
										.utcOffset(+0, true)
										.fromNow()}
									topic={cardTopic}
									titles={cardTitles}
								/>
								<CardBody
									Body={postBody}
									Proposal={votedPost.proposal}
									CardType={"card"}
									CardID={focusPostID}
								/>
								<div className='option-container'>
									{totalOptionData
										.filter((item) => item)
										.map(function (item, index) {
											return (
												<li key={index} className='option-wrapper'>
													<CardOption
														getVoteNumbers={getVoteNumbers}
														item={item}
														optionText={item.option}
														voteHandler={null}
														votes={item.votes}
														length={item.length}
														pressed={pressed}
														setPressed={setPressed}
														voted={voted}
														setVoted={setVoted}
														decision={decision}
														index={index}
														totalVotes={totalVotes}
														CardType={"focusPost"}
														setTotalVotes={setTotalVotes}
													/>
												</li>
											);
										})}
									{onGov && voted && (
										<div>
											<div>
												{verifiedOptionData
													.filter((item) => item)
													.map(function (item, index) {
														return (
															<li key={index} className='option'>
																{/* <p>{d.option}</p>
																		<p>{d.votes}</p> */}
																<CardOption
																	getVoteNumbers={getVoteNumbers}
																	item={item}
																	optionText={item.option}
																	voteHandler={null}
																	votes={item.votes}
																	length={item.length}
																	pressed={pressed}
																	setPressed={setPressed}
																	voted={voted}
																	setVoted={setVoted}
																	decision={decision}
																	index={index}
																	totalVotes={totalVotes}
																	CardType={"focusPost"}
																	setTotalVotes={setTotalVotes}
																/>
															</li>
														);
													})}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
