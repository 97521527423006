import React from "react";
import { TextField, Button } from "@material-ui/core";

export default function InputMessageModal({
	decision,
	message,
	subMessage,
	errorMessage,
	visible,
	setVisible,
	label,
	value,
	setValue,
	submitVisible,
	clickHandler,
	closeVisible,
}) {
	return (
		<div className={visible ? "modal-container" : "hidden"}>
			<div className={visible ? "modal" : "hidden"}>
				<h6>You Voted for: {decision}</h6>
				<h5>{message}</h5>
				<h6>{subMessage}</h6>
				{errorMessage && <h6 className="danger">Invalid Code</h6>}
				<div className="text-field-wrapper">
					<TextField
						label={label}
						color={"primary"}
						variant={"filled"}
						InputProps={{ className: "input" }}
						InputLabelProps={{ className: "label" }}
						value={value}
						onChange={(e) => {
							setValue(e.target.value);
						}}
						style={{ width: "100%" }}
						InputProps={{
							endAdornment: (
								<div>
									{submitVisible && (
										<Button
											onClick={clickHandler}
											color="primary"
											variant="contained"
											size="medium"
											style={{ borderRadius: 5 }}
										>
											Submit
										</Button>
									)}
								</div>
							),
						}}
					/>
				</div>
				{closeVisible && (
					<button
						onClick={() => {
							setVisible(false);
							setValue("");
						}}
					>
						<p className="slicedID">Close X</p>
					</button>
				)}
			</div>
		</div>
	);
}
