import client from "./client";

const endpoint = "/auth";

const login = ({ email, password }) =>
	client.post(`${endpoint}/login`, { email, password });

const getLocation = (token) => client.post(`${endpoint}/location`, { token });

const getNotifications = (token) =>
	client.post(`${endpoint}/notifications`, { token });

const getEmails = (token) => client.post(`${endpoint}/emails`, { token });

const getGroups = () => client.post(`${endpoint}/groups`);

const getTokens = (token) => client.post(`${endpoint}/tokens`, { token });

const getPhone = (token) => client.post(`${endpoint}/phone`, { token });

const getTopics = (token) => client.post(`${endpoint}/topics`, { token });

const getVotes = (token) => client.post(`${endpoint}/votes`, { token });

const getBlockedUsers = (token) =>
	client.post(`${endpoint}/blockedusers`, { token });

const getProfileSetUp = (token) =>
	client.post(`${endpoint}/profilesetup`, { token });

const getUserType = (token) =>
	client.post(`${endpoint}/blockedusers`, { token });

const authUserApi = {
	login,
	getLocation,
	getNotifications,
	getBlockedUsers,
	getEmails,
	getGroups,
	getTokens,
	getTopics,
	getVotes,
	getProfileSetUp,
	getUserType,
	getPhone,
};

export default authUserApi;
