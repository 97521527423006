import React, { useEffect } from "react";
import { useParams } from "react-router";

import AppStore from "../../assets/images/app_store_download.png";
import PlayStore from "../../assets/images/google-play-badge-logo-png-transparent.png";
import CardContainer from "../../components/shared/cardComponents/cardContainer";
import FocusPostPreview from "../../components/shared/focusPostPreview";
export default function JoinGroupLink() {
	const { pathID } = useParams();
	const url = `quae:///--/joinGroupLink?postID=${pathID}`;

	return (
		<div className='focus-post-wrapper'>
			<div className='sub-header'>
				<h3>Redirecting to app...</h3>
				<p>
					Didn't open?{" "}
					<a href={url} target='_blank' className='sub-header'>
						Click here
					</a>
				</p>
			</div>
			<a href='https://quae.app' target='_blank' className='sub-header'>
				<p>Learn more</p>
			</a>
			{/* <FocusPostPreview pathID={pathID} /> */}
		</div>
	);
}
