import React, { useEffect } from "react";

import { Context } from "../providers/Provider.jsx";
import { useContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import AdminRouter from "../navigation/adminRouter";
import HomeRouter from "../navigation/homeRouter";
import LoginRouter from "../navigation/loginRouter";
import GroupRouter from "./groupSettingsRouter";
import ExploreRouter from "../navigation/exploreRouter";
import ProfileRouter from "../navigation/profileRouter";
import OtherProfile from "../screens/profiles/otherProfile";
import AdminNavbar from "../components/shared/adminNavbar";
import NavBar from "../components/shared/navBar";
import AdminNavBar from "../components/shared/adminNavbar";
import Header from "../components/shared/header/header.js";
import HomeHeader from "../components/homeComponents/homeHeader.js";
import LoginHeader from "../components/shared/loginHeader";
import Footer from "../components/shared/footer.js";
import LoginFooter from "../components/shared/footer";
import GroupsTab from "../screens/group/groupsTab.js";
import GroupsRouter from "./groupsRouter.js";
import Loading from "../screens/login/loading.js";
import SettingsRouter from "./settingsRouter.js";
import FocusPost from "../screens/home/focusPost.js";
import QuickPollFocusPost from "../screens/home/quickPollFocusPost";
import SignUp from "../screens/login/signUp.js";
import Landing from "../screens/login/landing.js";
import Redirect from "../screens/quickScreens/redirect.js";
import JoinGroupLink from "../screens/quickScreens/joinGroupLink.js";
function App() {
	const { loggedIn } = useContext(Context);
	const VisibleNavBar = () => {
		if (loggedIn) {
			return (
				<div className='navbar'>
					<NavBar />
				</div>
			);
		} else {
			return <div></div>;
		}
	};
	const VisibleHeader = () => {
		if (loggedIn) {
			return <Header />;
		} else {
			return <LoginHeader />;
		}
	};
	const VisibleFooter = () => {
		if (loggedIn) {
			return <Footer />;
		} else {
			return <LoginFooter />;
		}
	};

	return (
		<div id='app-container'>
			{/* <div id="app-header">
				<VisibleHeader />
			</div> */}
			<div id='app-content-container'>
				<Switch>
					{/* <Route path="/admin">
						<AdminRouter />
					</Route>
					<Route path="/groups">
						<GroupsRouter />
					</Route>
					<Route path="/home">
						<HomeRouter />
					</Route>
					<Route path="/explore">
						<ExploreRouter />
					</Route>
					<Route path="/profile">
						<ProfileRouter />
					</Route>
					<Route path="/otherProfile">
						<OtherProfile />
					</Route>
					<Route path="/settings">
						<SettingsRouter />
					</Route>
					<Route path="/login">
						<LoginRouter />
					</Route> */}
					{/* <Route path='/signup'>
						<Loading />
						<SignUp />
					</Route> */}
					<Route path='/redirect/:pathID'>
						{/* <Loading /> */}
						<Redirect />
					</Route>
					<Route path='/joinGroupLink/:pathID'>
						{/* <Loading /> */}
						<JoinGroupLink />
					</Route>
					<Route path='/:pathID'>
						{/* <Loading /> */}
						<QuickPollFocusPost />
					</Route>
					<Route path='/'>
						<Landing />
						{/* <FocusPost /> */}
					</Route>
				</Switch>
			</div>
		</div>
	);
}

export default App;
