import { create } from "apisauce";
import authStorage from "../auth/storage";
//import settings from "../config/settings";

const apiClient = create({
	baseURL: "https://quaeapplication.herokuapp.com/",
});

apiClient.addAsyncRequestTransform(async (request) => {
	const authToken = await authStorage.getToken();
	if (!authToken) return;
	request.headers["authorization"] = "Bearer " + authToken;
});

export default apiClient;
