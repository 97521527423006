import client from "./client";

const endpoint = "/user";

const getUser = (userID) => client.get(`${endpoint}/me/${userID}`);

const getOtherUser = (userID) => client.get(`${endpoint}/otheruser/${userID}`);

const signUp = ({ email, password, userType, createdAt }) =>
	client.post(`${endpoint}/signup`, { email, password, userType, createdAt });

const deleteProfilePic = () => client.post(`${endpoint}/deleteprofilepic`);

const addNewEmail = ({ email }) =>
	client.post(`${endpoint}/addnewemail`, { email });

const resendEmail = () => client.post(`${endpoint}/resendemail`);

const verifyEmailCode = ({ userID, code }) =>
	client.post(`${endpoint}/verifyemailcode/${userID}`, { code });
const verifySubEmail = ({ email, code }) =>
	client.post(`${endpoint}/verifysubemail`, { code, email });

const resendSubEmail = ({ email }) =>
	client.post(`${endpoint}/resendsubemail`, { email });

const sendPhoneCode = ({ phone }) =>
	client.post(`${endpoint}/sendphonecode`, { phone });

const verifyPhoneCode = ({ code }) =>
	client.post(`${endpoint}/verifyphonecode`, { code });

const verifyPostCardCode = ({ code }) =>
	client.post(`${endpoint}/verifycode/`, { code });

const sendForgotPassEmail = (email) =>
	client.post(`${endpoint}/forgotpasswordcode/`, { email });

const verifyForgotPassword = ({ email, newPass, code }) =>
	client.post(`${endpoint}/forgotpasswordconfirm/`, {
		email,
		newPass,
		code,
	});

const addVote = ({ topic, identifier }) =>
	client.post(`${endpoint}/addvote`, {
		topic,
		identifier,
	});

const getProcessed = () => client.post(`${endpoint}/getprocessed`);

const uploadLocation = ({ address, state, city, country, zipCode, district }) =>
	client.post(`${endpoint}/upload/profilelocation`, {
		address,
		state,
		city,
		country,
		zipCode,
		district,
	});

const uploadName = ({ name }) =>
	client.post(`${endpoint}/upload/profileinfo/`, {
		name,
	});

const uploadBirthday = ({ birthday }) =>
	client.post(`${endpoint}/upload/birthday/`, {
		birthday,
	});

const blockUser = ({ otherUserID }) =>
	client.post(`${endpoint}/blockuser`, {
		otherUserID,
	});

const unBlockUser = ({ otherUserID }) =>
	client.post(`${endpoint}/unblockuser`, {
		otherUserID,
	});

const getBlockedUsers = () => client.post(`${endpoint}/myblockedusers`);

const uploadNotificationToken = ({ expoNotificationToken }) =>
	client.post(`${endpoint}/notificationtoken`, {
		expoNotificationToken,
	});
const getUserName = (userID) => client.get(`${endpoint}/name/${userID}`);

const usersApi = {
	getUser,
	signUp,
	getOtherUser,
	deleteProfilePic,
	addNewEmail,
	resendEmail,
	sendPhoneCode,
	verifyPhoneCode,
	verifyEmailCode,
	verifyPostCardCode,
	resendSubEmail,
	verifySubEmail,
	addVote,
	uploadLocation,
	uploadName,
	uploadBirthday,
	getProcessed,
	sendForgotPassEmail,
	verifyForgotPassword,
	blockUser,
	unBlockUser,
	getBlockedUsers,
	uploadNotificationToken,
	getUserName,
};
export default usersApi;
