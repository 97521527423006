import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { useParams } from "react-router";
import { TextField, Button } from "@material-ui/core";

import { Context } from "../../providers/Provider";
import postsApi from "../../api/posts";
import usersApi from "../../api/users";

import CardOption from "../../components/shared/cardComponents/cardOption";
import CardBody from "../../components/shared/cardComponents/cardBody";
import CardHeader from "../../components/shared/cardComponents/cardHeader";

import MessageModal from "../../components/shared/modalComponents/messageModal";
import InputMessageModal from "../../components/shared/modalComponents/inputMessageModal";

import AppStore from "../../assets/images/app_store_download.png";
import PlayStore from "../../assets/images/google-play-badge-logo-png-transparent.png";
export default function QuickPollFocusPost() {
	const { pathID } = useParams();
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [errorMessage, setErrorMessage] = useState(false);
	const [enterEmailModalVisible, setEnterEmailModalVisible] = useState(false);
	const [enterCodeModalVisible, setEnterCodeModalVisible] = useState(false);
	const [unavailableModalVisible, setUnavailableModalVisible] = useState(false);
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const [alreadyVotedModalVisible, setAlreadyVotedModalVisible] =
		useState(false);

	const [errMessage, setErrMessage] = useState("");
	const [voted, setVoted] = useState(false);
	const [pressed, setPressed] = useState(false);
	const [cardTotalOptionData, setCardTotalOptionData] = useState([]);
	const [cardDecision, setCardDecision] = useState("");
	const [errDecision, setErrDecision] = useState("");
	const [requiredDomain, setRequiredDomain] = useState("");
	const {
		userID,

		globalIdentifier,
		onGov,
		focusPostID,
	} = useContext(Context);
	const PostID = focusPostID;

	const [cardTopic, setCardTopic] = useState("");
	const [cardName, setCardName] = useState("");
	const [cardProfilePic, setCardProfilePic] = useState("");
	const [cardDate, setCardDate] = useState("");
	const [cardUserID, setCardUserID] = useState("123456789123456789012345");
	const [cardTitles, setCardTitles] = useState([]);

	const [relDate, setRelDate] = useState("");

	const [optionsArray, setOptionsArray] = useState([]);
	const [optionsValues, setOptionsValues] = useState([]);
	const [prioritized, setPrioritized] = useState(true);
	const [resolved, setResolved] = useState(true);

	const [tempDecision, setTempDecision] = useState(null);
	const [verifiedOptionData, setVerifiedOptionData] = useState([]);
	const [unverifiedOptionData, setUnverifiedOptionData] = useState([]);
	const [totalOptionData, setTotalOptionData] = useState([]);
	const [votedPost, setVotedPost] = useState({
		proposal: "",
		body: "",
		createdAt: "",
	});

	const [postBody, setPostBody] = useState("none");
	var [isPress, setIsPress] = useState(4);
	const [decision, setDecision] = useState("none");

	const [totalVotes, setTotalVotes] = useState(null);
	const [postOptionsType, setPostOptionsType] = useState("");
	const [postLoading, setPostLoading] = useState(true);
	const verifiedDataFunction = (dataKeys, dataValues) => {
		let tempArray = [];
		var i;
		let totalVerifiedVotes = dataValues.reduce((a, b) => a + b, 0);
		for (i = 0; i < dataKeys.length; i++) {
			tempArray.push({
				option: dataKeys[i],
				votes: dataValues[i],
				length: ((100 * dataValues[i]) / totalVerifiedVotes).toString() + "%",
			});
		}
		return tempArray;
	};

	const totalDataFunction = (
		dataKeys,
		dataValuesUnverified,
		dataValuesVerified
	) => {
		let tempArray = [];
		var i;
		let totalVotes =
			dataValuesUnverified.reduce((a, b) => a + b, 0) +
			dataValuesVerified.reduce((a, b) => a + b, 0);
		for (i = 0; i < dataKeys.length; i++) {
			tempArray.push({
				option: dataKeys[i],
				votes: dataValuesUnverified[i] + dataValuesVerified[i],
				length:
					(
						(100 * (dataValuesUnverified[i] + dataValuesVerified[i])) /
						totalVotes
					).toString() + "%",
			});
		}
		return tempArray;
	};

	const getVoteNumbers = async () => {
		const result = await postsApi.getVoteNumbers(pathID);
		if (!result.ok) {
			console.log({ getVoteNumbersErr: result });
		} else {
			setTotalOptionData(
				totalDataFunction(
					Object.keys(result.data.unverified),
					Object.values(result.data.unverified),
					Object.values(result.data.verified)
				)
			);
			setCardTotalOptionData(
				totalDataFunction(
					Object.keys(result.data.unverified),
					Object.values(result.data.unverified),
					Object.values(result.data.verified)
				)
			);
			setVerifiedOptionData(
				verifiedDataFunction(
					Object.keys(result.data.verified),
					Object.values(result.data.verified)
				)
			);
			setUnverifiedOptionData(
				verifiedDataFunction(
					Object.keys(result.data.unverified),
					Object.values(result.data.unverified)
				)
			);
			setTotalVotes(
				Object.values(result.data.unverified).reduce((a, b) => a + b, 0) +
					Object.values(result.data.verified).reduce((a, b) => a + b, 0)
			);
		}
	};

	const getPost = async () => {
		setPostLoading(true);
		const result = await postsApi.getPost(pathID);
		if (!result.ok) {
			console.log({ getPostErr: result });
		} else {
			setVotedPost(result.data);

			if (result.data.priority) {
				if (result.data.priority.prioritized) {
					setPrioritized(true);
				} else {
					setPrioritized(false);
				}
			}
			if (result.data.resolved) {
				setResolved(true);
			} else {
				setResolved(false);
			}
			setCardUserID(result.data.userID);
			setPostBody(result.data.body);
			setCardTopic(result.data.topic);
			setPostOptionsType(result.data.optionsType);
			if (result.data.options) {
				setOptionsArray(Object.keys(result.data.options.verified));
				setOptionsValues(Object.values(result.data.options.verified));
				setVerifiedOptionData(
					verifiedDataFunction(
						Object.keys(result.data.options.verified),
						Object.values(result.data.options.verified)
					)
				);
				setUnverifiedOptionData(
					verifiedDataFunction(
						Object.keys(result.data.options.unverified),
						Object.values(result.data.options.unverified)
					)
				);
				setTotalVotes(
					Object.values(result.data.options.unverified).reduce(
						(a, b) => a + b,
						0
					) +
						Object.values(result.data.options.verified).reduce(
							(a, b) => a + b,
							0
						)
				);
				setTotalOptionData(
					totalDataFunction(
						Object.keys(result.data.options.unverified),
						Object.values(result.data.options.unverified),
						Object.values(result.data.options.verified)
					)
				);
			}

			// checkAdmin(result.data.identifier);
			getUser();
		}
	};

	const getUser = async () => {
		const result = await usersApi.getUser(cardUserID);
		if (!result.ok) {
			console.log({ getProfileSetUpErr: result });
		} else {
			setCardName(result.data.name);
			setCardProfilePic(result.data.profilePic);
			if (result.data.userTitles) {
				setCardTitles(result.data.userTitles[globalIdentifier]);
			}
			setPostLoading(false);
		}
	};

	const voteHandler = async (item) => {
		setPressed(true);
		setDecision(item);
		setCardDecision(item);
		setTempDecision(item);
		setEnterEmailModalVisible(true);
		//set email modal visible
	};
	const vote = async (decision, postID, topic, code, email) => {
		const result = await postsApi.quickVote({
			postID: pathID,
			decision: decision.option,
			time: new Date(moment()),
			code,
			email,
		});
		if (!result.ok) {
			console.log({ voteErr: result });
		} else {
			setVoted(true);
			getVoteNumbers();
			console.log("vote function: voted");
		}
	};

	const checkActive = async () => {
		const result = await postsApi.checkActive(pathID);
		if (!result.ok) {
			console.log("checkActiveErr");
		} else {
			console.log(result);
			if (!result.data) {
				setUnavailableModalVisible(true);
			} else {
				getPost(pathID);
				setUnavailableModalVisible(false);

				setRequiredDomain(result.data);
			}
		}
	};
	const sendVoteCode = async () => {
		console.log(email, pathID);
		const result = await postsApi.sendVoteCode({ postID: pathID, email });
		if (!result.ok) {
			console.log("checkActiveErr");
		} else {
			//set code visible
			setEnterEmailModalVisible(false);
			setEnterCodeModalVisible(true);
		}
	};
	const handleEmail = () => {
		if (requiredDomain !== "none") {
			if (email.includes(requiredDomain)) {
				sendVoteCode();
				setEnterEmailModalVisible(false);

				setEnterCodeModalVisible(true);
				console.log("Email Handler");
			} else {
				setErrMessage("This is not available for your email domain");
			}
		} else {
			sendVoteCode();
			setEnterEmailModalVisible(false);

			setEnterCodeModalVisible(true);
			console.log("Email Handler");
		}
	};
	const quickVote = async () => {
		const result = await postsApi.quickVote({
			postID: pathID,
			decision: decision.option,
			time: new Date(moment()),
			code,
			email,
		});
		if (!result.ok) {
			console.log("checkActiveErr");
			setErrorMessage(true);
		} else {
			// setVoted(true);
			// getVoteNumbers();
			if (result.data !== "alreadyvoted") {
				setEnterCodeModalVisible(false);
				setConfirmModalVisible(true);
			} else {
				setErrMessage("You've already voted on this post.");
				setAlreadyVotedModalVisible(true);
				setEnterCodeModalVisible(false);
			}
			console.log("vote function: voted");
		}
	};

	useEffect(() => {
		checkActive(pathID);

		if (userID === "") {
			setIsPress(1);
		}
		if (userID !== "") {
			setIsPress(4);
		}
		setRelDate(
			moment(votedPost.createdAt, "YYYYMMDD hh:mm:ss")
				.utcOffset(+0, true)

				.fromNow()
		);
		setCardDate(relDate);
	}, [cardTopic, cardDate]);
	const [blurred, setBlurred] = useState(false);
	useEffect(() => {
		if (
			enterEmailModalVisible ||
			enterCodeModalVisible ||
			confirmModalVisible ||
			alreadyVotedModalVisible ||
			unavailableModalVisible
		) {
			setBlurred(true);
		} else {
			setBlurred(false);
		}
		console.log("email", enterEmailModalVisible);
		console.log("code", enterCodeModalVisible);
		console.log("alreadyVoted", alreadyVotedModalVisible);
		console.log("unavailable", unavailableModalVisible);
	}, [
		enterEmailModalVisible,
		enterCodeModalVisible,
		confirmModalVisible,
		alreadyVotedModalVisible,
		unavailableModalVisible,
	]);

	return (
		<div className="focus-post-wrapper">
			<div className={unavailableModalVisible ? "modal-container" : "hidden"}>
				<div className="sub-header">
					<h3>Download Quae to Vote Every Day</h3>
					<a
						href="https://apps.apple.com/us/app/quae/id1555653993"
						target="_blank"
					>
						<img src={AppStore} className="app-store" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=com.quae.quaeapp&hl=en_US&gl=US"
						target="_blank"
					>
						<img src={PlayStore} className="app-store" />
					</a>
				</div>
			</div>
			<MessageModal
				message={"Oops, This Post Link Expired"}
				subMessage={"Contact the Poll Author to Restart the Link"}
				visible={unavailableModalVisible}
				setVisible={setUnavailableModalVisible}
			/>
			<MessageModal
				message={"Oops, you've already voted on this post"}
				visible={alreadyVotedModalVisible}
				setVisible={setAlreadyVotedModalVisible}
			/>
			<InputMessageModal
				decision={decision.option}
				message={"Enter Your Email to Verify Your Vote"}
				visible={enterEmailModalVisible}
				setVisible={setEnterEmailModalVisible}
				label="Email"
				value={email}
				setValue={setEmail}
				clickHandler={() => handleEmail()}
				submitVisible={email.includes("@") && email.includes(".")}
				closeVisible={true}
			/>
			<InputMessageModal
				decision={decision.option}
				message={"We've emailed you a verification code"}
				subMessage={"Enter your code below"}
				errorMessage={errorMessage}
				visible={enterCodeModalVisible}
				setVisible={setEnterCodeModalVisible}
				label="Verification Code"
				value={code}
				setValue={setCode}
				clickHandler={() => quickVote()}
				submitVisible={code.length === 7}
				closeVisible={true}
			/>

			<div className={confirmModalVisible ? "modal-container" : "hidden"}>
				<div className={confirmModalVisible ? "modal" : "hidden"}>
					<h4>Thank You! Your Vote Has Been Recorded</h4>
					<h6>You Can close this tab now</h6>
					<h4 style={{ paddingTop: 30 }}>Join The Stevens Quae Community</h4>
					<a
						href="https://apps.apple.com/us/app/quae/id1555653993"
						target="_blank"
					>
						<img src={AppStore} className="app-store" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=com.quae.quaeapp&hl=en_US&gl=US"
						target="_blank"
					>
						<img src={PlayStore} className="app-store" />
					</a>
					<button onClick={() => setConfirmModalVisible(false)}>
						<p className="slicedID">Close X</p>
					</button>
				</div>
			</div>
			<div>
				{!postLoading && (
					<div className={blurred ? "blurred" : ""}>
						<div className="sub-header">
							<h3>Download Quae to Vote Every Day</h3>
							<a
								href="https://apps.apple.com/us/app/quae/id1555653993"
								target="_blank"
							>
								<img src={AppStore} className="app-store" />
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.quae.quaeapp&hl=en_US&gl=US"
								target="_blank"
							>
								<img src={PlayStore} className="app-store" />
							</a>
						</div>

						<div className="card">
							<div className="card-content">
								<CardHeader
									cardHeaderUserID={votedPost.UserID}
									cardProfilePic={cardProfilePic}
									cardName={cardName}
									slicedID={cardUserID.slice(20, 24)}
									cardDate={moment(votedPost.createdAt, "YYYYMMDD hh:mm:ss")
										.utcOffset(+0, true)
										.fromNow()}
									topic={cardTopic}
									titles={cardTitles}
								/>
								<CardBody
									Body={postBody}
									Proposal={votedPost.proposal}
									CardType={"card"}
									CardID={focusPostID}
								/>
								<div className="option-container">
									{totalOptionData
										.filter((item) => item)
										.map(function (item, index) {
											return (
												<li key={index} className="option-wrapper">
													<div onClick={() => voteHandler(item)}>
														<CardOption
															getVoteNumbers={getVoteNumbers}
															item={item}
															optionText={item.option}
															voteHandler={voteHandler}
															votes={item.votes}
															length={item.length}
															pressed={pressed}
															setPressed={setPressed}
															voted={voted}
															setVoted={setVoted}
															decision={decision}
															index={index}
															totalVotes={totalVotes}
															CardType={"focusPost"}
															setTotalVotes={setTotalVotes}
														/>
													</div>
												</li>
											);
										})}
									{onGov && voted && (
										<div>
											<div>
												{verifiedOptionData
													.filter((item) => item)
													.map(function (item, index) {
														return (
															<li key={index} className="option">
																{/* <p>{d.option}</p>
																		<p>{d.votes}</p> */}
																<CardOption
																	getVoteNumbers={getVoteNumbers}
																	item={item}
																	optionText={item.option}
																	voteHandler={voteHandler}
																	votes={item.votes}
																	length={item.length}
																	pressed={pressed}
																	setPressed={setPressed}
																	voted={voted}
																	setVoted={setVoted}
																	decision={decision}
																	index={index}
																	totalVotes={totalVotes}
																	CardType={"focusPost"}
																	setTotalVotes={setTotalVotes}
																/>
															</li>
														);
													})}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

{
	/* <div>
								{!prioritized && globalAdmin && !tempPrioritized && (
									<button
										onClick={() => {
											setPrioritizing(!prioritizing);
										}}
									>
										prioritize post
									</button>
								)}
								{!resolved && globalAdmin && !tempResolved && (
									<button
										onClick={() => {
											setResolving(!resolving);
										}}
									>
										resolve post
									</button>
								)}
							</div>

							{prioritizing && (
								<div>
									{/* <PriorityRange {...priorityProps} /> 
									<div>
										<button
											onClick={() => {
												setPrioritizing(false);
												setTempPrioritized(true);
												prioritizePost();
											}}
										>
											confirm
										</button>
										<button
											onClick={() => {
												setPrioritizing(false);
											}}
										>
											cancel
										</button>
									</div>
								</div>
							)}

							{tempPrioritized && (
								<div>
									<div>Successfully Prioritized</div>
								</div>
							)}

							{resolving && (
								<div>
									<div>
										<button
											onClick={() => {
												setResolving(false);
												setTempResolved(true);
												resolvePost();
											}}
										>
											confirm
										</button>
										<button
											onClick={() => {
												setResolving(false);
											}}
										>
											cancel
										</button>
									</div>
								</div>
							)}

							{tempResolved && <div>Successfully Resolved</div>} */
}

// {voted && (
// 	<button
// 		onClick={() => {
// 			// setModalPostAnalyticsVisible(true);
// 			history.push("/home/analytics");
// 		}}
// 	>
// 		Analytics
// 	</button>
// )}

// <div>
// 							<div>
// 								{/* <MaterialIcons
// 												name='comment'
// 												size={24}
// 												color={colors.border}
// 											/> */}
// 								<div>{chats.length}</div>
// 							</div>
// 							<div>
// 								{!undoAnimationVisible && pressed && (
// 									<div>
// 										{/* <Animatable.Image
// 														source={require("../../assets/images/purple_check_vote.png")}
// 														style={{ height: 30, width: 30 }}
// 														animation={"bounceIn"}
// 														duration={500}
// 													/> */}
// 									</div>
// 								)}
// 								{undoAnimationVisible && (
// 									<button
// 										onClick={() => {
// 											// handleCancelUndo();
// 										}}
// 									>
// 										cancel vote
// 									</button>
// 								)}
// 							</div>

// 							<button
// 								onPress={() => {
// 									if (loggedIn === false) {
// 										// setModalLoggedInVisible(true);
// 									}
// 									if (loggedIn === true && profileSetUp === true) {
// 										// setModalReportVisible(true);
// 									} else if (profileSetUp === false && loggedIn === true) {
// 										// setModalCompleteProfileVisible(true);
// 									} else {
// 										// setModalLoggedInVisible(true);
// 									}
// 								}}
// 							>
// 								report
// 								{/* <SimpleLineIcons
// 												name='flag'
// 												size={16}
// 												color={theme.COLOR_URGENT}
// 											/> */}
// 							</button>
// 						</div>
