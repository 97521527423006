import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Context } from "../../../providers/Provider.jsx";

export default function CardBody({ Body, Proposal, CardType, CardID }) {
	const { setFocusPostID } = useContext(Context);
	const [fullBody, setFullBody] = useState(false);
	const bodyLength = 140;
	const history = useHistory();

	useEffect(() => {
		if (CardType === "focusPost") {
			setFullBody(true);
		}
		if (Body.length < bodyLength) {
			setFullBody(true);
		}
	}, []);

	return (
		<div className='card-body'>
			<h6>{Proposal}</h6>
			{Body && (
				<div>
					{Body !== "none" && (
						<div>
							<div>
								<p>{Body}</p>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
